import { Observable } from 'rxjs';
import {
  RecoverPasswordResetPayload,
  RecoverPasswordResetResponse,
} from '../dto/recover.dto';
import {
  ValidatePasswordResetPayload,
  ValidatePasswordResetResponse,
} from '../dto/validate.dto';
import {
  ResetPasswordResetPayload,
  ResetPasswordResetResponse,
} from '../dto/reset.dto';

export abstract class PasswordResetRepository {
  abstract recover(
    payload: RecoverPasswordResetPayload
  ): Observable<RecoverPasswordResetResponse>;

  abstract validate(
    payload: ValidatePasswordResetPayload
  ): Observable<ValidatePasswordResetResponse>;

  abstract reset(
    payload: ResetPasswordResetPayload
  ): Observable<ResetPasswordResetResponse>;
}
